import React, { Fragment, useContext, useState, useEffect } from 'react'
import { Form, Row, Col, Card, } from 'react-bootstrap';
import "../../../components/assets/style.css";
import { useCookies } from "react-cookie";
import Axios from "axios";
import {  DropdownList } from 'react-widgets';
import { useAlert } from 'react-alert';


export default function AuctionInfo({ auctions, setAuctions, curAuction, setCurAuction }) {
    const [cookies] = useCookies(["user"]);
    const [auctionTitle,setAuctionTitle] = useState("")
    const [auctionType,setAuctionType] = useState("")
    const [auctionStartingDate,setAuctionStartingDate]=useState("")
    const [error, setError] = useState();
    const alert = useAlert()

    useEffect(() => {
        const compMount = async (e) => {
            let token = localStorage.getItem("auth-token");
            if (token == null) {
                localStorage.setItem("auth-token", "");
                token = "";
            }
            else {
                const tokenResponse = await Axios.post(
                    "/api/users/tokenIsValid",
                    null,
                    { headers: { "x-auth-token": token } }
                );
                if (tokenResponse.data) {
                    try {
                        const auctionRes = await Axios.get(
                            "/api/auctions/",
                            { headers: { "x-auth-token": token } }
                        )

                        if (auctionRes.data) {
                            const temp = []
                            // console.log(catalogRes.data)
                            for (var i = 0; i < auctionRes.data.auctions.length; i++) {
                                temp.push(auctionRes.data.auctions[i].auctionID)
                            }
                            // console.log(temp)
                            setAuctions(temp);
                        }

                    } catch (error) {
                        console.log(error)
                        alert.error(<div style={{ 'fontSize': '0.70em' }}>Error retrieving auctions</div>)
                    }


                }
            }
        }
        compMount()
    }, [])

    const handleCurAuction = async (value) => {
        setCurAuction(value)
        
        let token = localStorage.getItem("auth-token");
        if (token == null) {
            localStorage.setItem("auth-token", "");
            token = "";
        }
        else {
            const tokenResponse = await Axios.post(
                "/api/users/tokenIsValid",
                null,
                { headers: { "x-auth-token": token } }
            );
            if (tokenResponse.data) {
                try {
                    
                    const auctionRes = await Axios.get(
                        "/api/auctions/get",
                        { headers: { "x-auth-token": token },params:{auctionID:value} }
                    )

                    if (auctionRes.data) {
                        // console.log(auctionRes)
                        setAuctionTitle(auctionRes.data.auction.auctionTitle)
                        setAuctionType(auctionRes.data.auction.auctionType)
                        setAuctionStartingDate(auctionRes.data.auction.auctionStartingDate)
                    }

                } catch (error) {
                    console.log(error)
                    alert.error(<div style={{ 'fontSize': '0.70em' }}>Error retrieving auction</div>)
                }


            }
        }

    }

    return (
        <Fragment>

            <Card className="box-design mt-3 mb-3">
                <Row className="ml-3 pr-3 mt-3 ">

                    <Col xs={12} sm={12}>
                        <Form>
                            <Form.Group as={Row} >
                                <Form.Label column sm="1">
                                    Choose Auction
                                </Form.Label>
                                <Col sm="5">
                                    <DropdownList filter
                                        data={auctions ? auctions : []}
                                        value={curAuction}
                                        onChange={value => handleCurAuction(value)}
                                        textField="auctionID"
                                    />
                                </Col>
                                <Form.Label column sm="1">
                                    Auction Type
                                </Form.Label>
                                <Col sm="5">
                                    <Form.Control type="text" value={auctionType} readOnly />
                                </Col>

                            </Form.Group>
                            <Form.Group as={Row} >
                                <Form.Label column sm="1">
                                    Auction Date
                                </Form.Label>
                                <Col sm="5">
                                    <Form.Control type="text" value={auctionStartingDate} readOnly/>
                                </Col>
                                <Form.Label column sm="1">
                                    Auction Title
                                </Form.Label>
                                <Col sm="5">
                                    <Form.Control type="text" value={auctionTitle} readOnly/>
                                </Col>

                            </Form.Group>

                        </Form>
                    </Col>
                </Row>
            </Card>
        </Fragment>
    )
}
