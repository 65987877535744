import React, { useState, useEffect, Fragment, useContext, useRef } from 'react'
import ModuleHeader from "../ModuleHeader/ModuleHeader";
import { Form, Row, Col, Button, Card, ButtonGroup, Dropdown, InputGroup, FormControl } from 'react-bootstrap';
import { FaBroom, FaDumpsterFire, FaUpload, FaCopy, FaRecycle } from 'react-icons/fa';
import "../../../components/assets/style.css";
import { useCookies } from "react-cookie";
import 'react-widgets/dist/css/react-widgets.css';
import {  DropdownList } from 'react-widgets';
import "react-datetime/css/react-datetime.css";
import { useAlert } from 'react-alert';
import UserContext from "../../../context/UserContext";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Datetime from 'react-datetime';

import Axios from "axios";

const demoDescription = `
<p>All items in this auction start at <strong>$5 OR LESS</strong> with no reserve price. Item conditions are NEW, OPEN BOX, or LIKE NEW. We do inspect all items for full functionality. We do our best to describe items accurately, but we rely on manufacturer descriptions and cannot guarantee <strong>100%</strong> accuracy. We recommend that you personally inspect all items.</p>
<ul>
<li><strong>Payment:</strong> All winning bidders will have have 48 hours to pay. After 3 non-payment strikes you will be banned from bidding.</li>
<li><strong>Pickup Instructions:</strong> Bring your emailed receipt to 422 Hwy 51 N Hernando, MS 38632. Any item not removed after 7 days of the auction invoice date will be considered ABANDONED and NO REFUND WILL BE ISSUED.</li>
<li><strong>Refusal:</strong> Any item refused will have a <strong>15%</strong> restocking fee. Pickup Hours: Monday-FRIDAY 10:00 - 6:30 pm Contact: 662-782-4607</li>
</ul>
`

export default function CreateAuction() {
    const today = new Date()
    
    const alert = useAlert()
    const { userData } = useContext(UserContext);
    const [error, setError] = useState();
    const [cookies] = useCookies(["user"]);
    const [catalogs, setCatalogs] = useState([]);
    const [auctionID, setAuctionID] = useState("");
    const [auctionTitle, setAuctionTitle] = useState("")
    const [auctionCatalog, setAuctionCatalog] = useState('');
    const [successNotice, setSuccessNotice] = useState();
    const [auctionDate, setAuctionDate] = useState(today.getFullYear()+'-'+today.getMonth()+1+'-'+today.getDate());
    const [auctionType, setAuctionType] = useState("live");
    const [description, setDescription] = useState(demoDescription)
    const catalogNoCopyRef = useRef(null);
    

    useEffect(() => {
        setAuctionTitle(auctionCatalog + " " + auctionType + " Auction " + auctionDate)
    }, [auctionCatalog, auctionDate, auctionType])

    useEffect(() => {
        if (successNotice) {
            alert.success(<div style={{ 'fontSize': '0.70em' }}>{successNotice}</div>)
            setSuccessNotice(undefined)
        }
    }, [successNotice])


    useEffect(() => {
        const compMount = async (e) => {
            let token = localStorage.getItem("auth-token");
            if (token == null) {
                localStorage.setItem("auth-token", "");
                token = "";
            }
            else {
                const tokenResponse = await Axios.post(
                    "/api/users/tokenIsValid",
                    null,
                    { headers: { "x-auth-token": token } }
                );
                if (tokenResponse.data) {
                    try {
                        const catalogRes = await Axios.get(
                            "/api/auctions/catalogs/",
                            { headers: { "x-auth-token": token } }
                        )

                        if (catalogRes.data) {
                            const temp = []
                            // console.log(catalogRes.data)
                            for (var i = 0; i < catalogRes.data.catalogs.length; i++) {
                                temp.push(catalogRes.data.catalogs[i].catalog)
                            }
                            // console.log(temp)
                            setCatalogs(temp);

                        }

                    } catch (error) {
                        console.log(error)
                        alert.error(<div style={{ 'fontSize': '0.70em' }}>Error retrieving catalogs</div>)
                    }
                    
                    
                }
            }
        }
        compMount()
    }, [])


    const handleCatalogCreate = async (name) => {

        if (name) {
            let token = localStorage.getItem("auth-token");
            if (token == null) {
                localStorage.setItem("auth-token", "");
                token = "";
            }
            else {

                const tokenResponse = await Axios.post(
                    "/api/users/tokenIsValid",
                    null,
                    { headers: { "x-auth-token": token } }
                );
                if (tokenResponse.data) {
                    var uname = cookies.username

                    let data = { username: uname, catalog: name }
                    const resp = await Axios.post(`/api/auctions/catalogs/create`, data, { headers: { "x-auth-token": userData.token } });
                    const result = resp.data
                    try {
                        setSuccessNotice("New catalog added: " + result.catalog);
                        setAuctionCatalog(name)
                        setCatalogs(catalogs => [...catalogs, name])
                    } catch (err) {

                        setError("Error in creating catalog")
                    }
                }
            }
        }
        else {
            alert.error(<div style={{ 'fontSize': '0.70em' }}>Error creating catalog</div>)
        }
    }

    
    const handleAuctionCreate = async (e) => {
        console.log(auctionCatalog)
        if (auctionCatalog) {
            let token = localStorage.getItem("auth-token");
            if (token == null) {
                localStorage.setItem("auth-token", "");
                token = "";
            }
            else {

                const tokenResponse = await Axios.post(
                    "/api/users/tokenIsValid",
                    null,
                    { headers: { "x-auth-token": token } }
                );
                if (tokenResponse.data) {
                    var name = cookies.username
                    
                    let data = { name, auctionCatalog, auctionDate,auctionTitle,auctionType,description }
                    try {
                        const resp = await Axios.post(`/api/auctions/create`, data, { headers: { "x-auth-token": userData.token } });
                        const result = resp.data
                        // console.log(result.ipin)
                        setSuccessNotice("New pallet registered: " + result.auctionID);
                        setAuctionID(result.auctionID)

                    } catch (err) {
                        console.log(err)
                        setError("Error in creating pallet/Location Full")
                    }

                }
            }
        }
        else {
            alert.error(<div style={{ 'fontSize': '0.70em' }}>Error creating auction</div>)
        }
    }

   

    function copyToClipboard(e) {
        catalogNoCopyRef.current.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
        setSuccessNotice('Copied!');
    };

    const handleAuctionDateChange = (value) =>{
        // console.log(value)
        setAuctionDate(value.format("MM-DD-YYYY"))
    }

    return (
        <Fragment>
            <ModuleHeader moduleName={"Create new auction"} />
            <Card className="box-design mt-3">
                <Row className="ml-3 pr-3 mt-3">

                    <Col xs={12} sm={12}>
                        <Form>
                            <Form.Group as={Row} controlId="titleText">
                                <Form.Label column sm="2">
                                    Choose catalog
                                </Form.Label>
                                <Col sm="4">
                                    <DropdownList filter
                                        data={catalogs ? catalogs : []}
                                        value={auctionCatalog}
                                        allowCreate="onFilter"
                                        onCreate={name => handleCatalogCreate(name)}
                                        onChange={value => setAuctionCatalog(value)}
                                        textField="catalog"
                                    />
                                </Col>
                                <Form.Label column sm="1">
                                    Auction Date
                                </Form.Label>
                                <Col sm="5">
                                    {/* <Form.Control type="text" placeholder="Enter Truckload number" value={truckload} onChange={(e) => setTruckload(e.target.value)} /> */}
                                    <Datetime 
                                        defaultValue={auctionDate}
                                        dateFormat="MM-DD-YYYY"
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        onChange={(e) => handleAuctionDateChange( e )}
                                    />
                                    
                                </Col>

                            </Form.Group>

                            <Form.Group as={Row} >
                                <Form.Label column sm="2">
                                    Auction type
                                </Form.Label>
                                <Col sm="4">
                                    <DropdownList
                                        data={['live', 'timed']}
                                        value={auctionType}
                                        onChange={value => setAuctionType(value)}
                                        textField="auctionType"
                                    />
                                    {/* <Form.Control type="text" placeholder="Enter location" value={location} onChange={(e) => setCostPrice(e.target.value)} /> */}
                                </Col>
                                <Form.Label column sm="1">
                                    Title
                                </Form.Label>
                                <Col sm="5">
                                    <Form.Control type="text" placeholder="Usually generates automatically" value={auctionTitle} onChange={(e) => setAuctionTitle(e.target.value)} />
                                </Col>
                                <Form.Label column sm="12">
                                    Description
                                </Form.Label>
                                <Col sm="12" className="mt-2">
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={description}
                                        config={{
                                            removePlugins: ['Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed']
                                        }}

                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setDescription(data);
                                            // console.log({ event, editor, data });
                                        }}

                                    />
                                </Col>
                                <Col sm="6" className="mt-2">


                                    <InputGroup className="mb-3">
                                        <Form.Control ref={catalogNoCopyRef} placeholder="Auction ID will be generated here. Copy it" type="text" value={auctionID} readOnly />
                                        {
                                            document.queryCommandSupported('copy') &&
                                            <InputGroup.Append>
                                                <Button variant="secondary" onClick={copyToClipboard}><FaCopy /></Button>
                                            </InputGroup.Append>
                                        }
                                    </InputGroup>


                                </Col>
                                <Col sm="6" className="mt-2">
                                    <Button variant="info" block onClick={handleAuctionCreate}><FaUpload /> Create new auction </Button>
                                </Col>

                            </Form.Group>


                        </Form>
                    </Col>
                </Row>
            </Card>
        </Fragment>
    )
}
