import React, { Fragment, useState, useContext, useEffect } from 'react'
import { Form, Row, Col, Button, Card, ButtonGroup, Dropdown } from 'react-bootstrap'
import { FaBroom, FaDumpsterFire, FaUpload, FaCamera } from 'react-icons/fa';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "../../../components/assets/style.css";
import Axios from "axios";
import UserContext from "../../../context/UserContext";

import { useCookies } from "react-cookie";
import { useAlert } from 'react-alert';
import { useFileUpload } from "use-file-upload";
import { DropdownList, Multiselect } from 'react-widgets';
// import qz from "qz-tray"
// import ZPLLabelPrint from "../../labelprinter/ZPLLabelPrint";

const testPrintData="^XA^FX Top section with logo, name and address.^CF0,60^FO50,50^GB100,100,100^FS^FO75,75^FR^GB100,100,100^FS^FO93,93^GB40,40,40^FS^FO220,50^FDIntershipping, Inc.^FS^CF0,30^FO220,115^FD1000 Shipping Lane^FS^FO220,155^FDShelbyville TN 38102^FS^FO220,195^FDUnited States (USA)^FS^FO50,250^GB700,3,3^FS^FX Second section with recipient address and permit information.^CFA,30^FO50,300^FDJohn Doe^FS^FO50,340^FD100 Main Street^FS^FO50,380^FDSpringfield TN 39021^FS^FO50,420^FDUnited States (USA)^FS^CFA,15^FO600,300^GB150,150,3^FS^FO638,340^FDPermit^FS^FO638,390^FD123456^FS^FO50,500^GB700,3,3^FS^FX Third section with bar code.^BY5,2,270^FO100,550^BC^FD12345678^FS^FX Fourth section (the two boxes on the bottom).^FO50,900^GB700,250,3^FS^FO400,900^GB3,250,3^FS^CF0,40^FO100,960^FDCtr. X34B-1^FS^FO100,1010^FDREF1 F00B47^FS^FO100,1060^FDREF2 BL4H8^FS^CF0,190^FO470,955^FDCA^FS^XZ"


export default function DownloadedProductData({ title, upc, description, curAuction, retail, image, setTitle, setRetail, setUpc, setDescription, setImage }) {
    const { userData } = useContext(UserContext);
    const [cookies] = useCookies(["user"]);
    const [error, setError] = useState();
    const [successNotice, setSuccessNotice] = useState();
    
    const [vendorID,setVendorID] = useState('0xa5f')
    const [productID,setProductID] = useState('0x08c')
    const [interfaceCode,setInterfaceCode] = useState('')
    const [endpoint,setEndpoint] = useState('')
    
    const [quantity, setQuantity] = useState(1);
    const [auctionStartPrice, setAuctionStartPrice] = useState(50);
    const [condition, setCondition] = useState('New');
    const [shelves, setShelves] = useState([]);
    const [curShelf, setCurShelf] = useState("");
    const [curCategory, setCurCategory] = useState([]);
    const [categories,setCategories] = useState([]);
    const [files, selectFiles] = useFileUpload();
    // const [cameraState, setCameraState] = useState(false);

    const alert = useAlert()

    const handleSelect = (e) => {
        console.log(e);
        setCondition(e)
    }
    const increaseQuantity = () => {
        setQuantity(quantity + 1);
    }
    const decreaseQuantity = () => {
        if (quantity !== 1) {
            setQuantity(quantity - 1);
        }
    }

    const clearAll = () => {
        setTitle("")
        setUpc("")
        setRetail("")
        setDescription("")
        setImage("https://cdn.shopify.com/s/files/1/0514/3520/8854/files/surplus-auction.png?v=1609197903")
        setAuctionStartPrice("5")
        setQuantity("1")
        setCondition("New")

    }

    useEffect(() => {
        const compMount = async (e) => {
            let token = localStorage.getItem("auth-token");
            if (token == null) {
                localStorage.setItem("auth-token", "");
                token = "";
            }
            else {
                const tokenResponse = await Axios.post(
                    "/api/users/tokenIsValid",
                    null,
                    { headers: { "x-auth-token": token } }
                );
                if (tokenResponse.data) {
                    try {
                        const shelfRes = await Axios.get(
                            "/api/shelves/",
                            { headers: { "x-auth-token": token } }
                        )

                        if (shelfRes.data) {
                            const temp3 = []
                            // console.log(shelfRes.data)
                            for (var i = 0; i < shelfRes.data.shelves.length; i++) {
                                temp3.push(shelfRes.data.shelves[i].shelfNo)
                            }
                            // console.log(temp2)
                            setShelves(temp3);

                        }

                    } catch (error) {
                        console.log(error)
                        alert.error(<div style={{ 'fontSize': '0.70em' }}>Error retrieving location list</div>)
                    }
                    try {
                        const categoryRes = await Axios.get(
                            "/api/products/allcategories",
                            { headers: { "x-auth-token": token } }
                        )

                        if (categoryRes.data) {
                            const temp4 = []
                            // console.log(categoryRes.data)
                            for (var i = 0; i < categoryRes.data.length; i++) {
                                temp4.push(categoryRes.data[i].name+" ("+categoryRes.data[i].row_id+")")
                            }
                            // console.log(temp2)
                            setCategories(temp4);

                        }

                    } catch (error) {
                        console.log(error)
                        alert.error(<div style={{ 'fontSize': '0.70em' }}>Error retrieving categories</div>)
                    }

                }
            }
        }
        // const getInterface = async(e) =>{
        //     qz.websocket.connect().then(function() {
        //         const arr = qz.usb.listInterfaces({deviceInfo:{vendorId:vendorID,productId:productID}})
        //         if(arr.length>0){
        //             setInterfaceCode(arr[0])
        //         }
        //         const arr2 = qz.usb.listEndpoints({deviceInfo:{vendorId:vendorID,productId:productID,iface:interfaceCode}})
        //         if(arr2.length>0){
        //             setEndpoint(arr2[0])
        //         }                
        //     })
        // }
        // getInterface()
        compMount()
    }, [])

    // const sendUsbData = (data) =>{
    //     try{
    //         qz.usb.sendData(vendorID, productID, endpoint, data)
    //     }catch(displayError){
    //         console.log(displayError)
    //     }
    // }
    // const printLabel = async(e) => {
    //     try{
    //         await qz.websocket.connect().then(function() {
    //             return qz.usb.claimDevice({vendorID, productID, interfaceCode});
    //         }).then(function(){
    //             return sendUsbData(testPrintData)
                
    //         }).then(process).then(qz.usb.releaseDevice({vendorID, productID}))
    //     }catch(err){
    //         console.log(err)
    //     }
    // }
    const postProduct = async (e) => {
        e.preventDefault()
        try {
            if (title === "" || retail === "" || quantity === "" || image === "" || auctionStartPrice === "") {
                setError("Please fill up all the missing fields")
            }
            else {
                const res = await Axios.get(`/api/products/getsku`, { headers: { "x-auth-token": userData.token } });
                
                // console.log(res.data)
                if (res.data) {
                    var sku = res.data.sku;
                    
                    var descText='<p><strong>Retail price:</strong> $'+retail.toString()+'</p>'+description
                    const product = {
                        auctionID:curAuction,
                        title: title,
                        upc: upc,
                        sku: sku,
                        shelf:curShelf,
                        description: descText,
                        image: image,
                        retail: retail.toString(),
                        auctionStartPrice: auctionStartPrice.toString(),
                        condition: condition,
                        quantity: quantity,
                        category: curCategory
                    }
                    // console.log(product)
                    const username = cookies.username;
                    const productInp = product;
                    let data = { username, productInp }
                    // console.log(data)
                    const resp = await Axios.post(`/api/products/new`, data, { headers: { "x-auth-token": userData.token } });
                    const result = resp.data
                    try {
                        console.log(result)
                        setSuccessNotice("Product uploaded successfully. Posting id: " + result.lotID + ". SKU: " + sku);
                        clearAll();
                    } catch (err) {
                        setError("Error in posting product")
                    }
                }

            }
        }
        catch (err) {
            console.log(err)
            // err.response.data.msg && setError(err.response.data.msg);
        }
    }
    useEffect(() => {
        if (error) {
            alert.error(<div style={{ 'fontSize': '0.70em' }}>{error}</div>)
            setError(undefined)
        }
    }, [error])

    useEffect(() => {
        if (successNotice) {
            alert.success(<div style={{ 'fontSize': '0.70em' }}>{successNotice}</div>)
            setSuccessNotice(undefined)
        }
    }, [successNotice])

    const clearImage = (e) => {
        setImage('https://cdn.shopify.com/s/files/1/0514/3520/8854/files/surplus-auction.png?v=1609197903')
    }

    // const handleTakePhotoAnimationDone = (dataUri) => {
    //     setImage(dataUri)
    //     setCameraState(false)
    // }

    const handleCapture = (target) => {
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                const newUrl = URL.createObjectURL(file);
                setImage(newUrl);
            }
        }
    };

    const handleCurShelf = async (value) => {
        setCurShelf(value)
    }


    const handleCategoryCreate = async (name) => {

        if (name) {
            let token = localStorage.getItem("auth-token");
            if (token == null) {
                localStorage.setItem("auth-token", "");
                token = "";
            }
            else {

                const tokenResponse = await Axios.post(
                    "/api/users/tokenIsValid",
                    null,
                    { headers: { "x-auth-token": token } }
                );
                if (tokenResponse.data) {
                    var uname = cookies.username

                    let data = { username: uname, category: name }
                    const resp = await Axios.post(`/api/products/category/create`, data, { headers: { "x-auth-token": userData.token } });
                    if(resp){
                        const cat=resp.data.response;
                        setCurCategory([
                            ...curCategory,cat.name+" ("+cat.row_id+")"
                        ])
                        
                        setSuccessNotice("New category created: "+cat.row_id);
                    }
                }
            }
        }
        else {
            alert.error(<div style={{ 'fontSize': '0.70em' }}>Error creating catalog</div>)
        }
    }



    return (
        <Fragment>
            {/* {error && (
                        <ErrorNotice message={error} clearError={() => setError(undefined)} />
                    )}
            {successNotice && (
                        <SuccessNotice message={successNotice} clearSuccessNotice={() => setSuccessNotice(undefined)} />
                    )} */}
            <Card className="box-design mt-3">
                <Row className="ml-3 pr-3 mt-3">
                    <Col xs={12} sm={4} >
                        <Card className="productImageBox">

                            <Card.Img variant="top" src={image} className="productImageHolder" />
                            {/* {
                                (cameraState)
                                ? <Camera onTakePhotoAnimationDone = {handleTakePhotoAnimationDone}
                                    isFullscreen={true}
                                />
                                : <div></div>
                            } */}
                            <Card.Body>
                                <input
                                    accept="image/*"
                                    className="hidden"
                                    id="icon-button-file"
                                    type="file"
                                    capture="environment"
                                    onChange={(e) => handleCapture(e.target)}
                                />
                                <Form.Label htmlFor="icon-button-file" className="imageTakerLabel">
                                    <FaCamera /> Upload Photo
                                </Form.Label >
                                {/* <ButtonGroup aria-label="Photo-camera-file" style={{ display: 'flex', marginBottom: '10px' }}>

                                    {/* <Button variant="primary"
                                        onClick={() =>
                                            setCameraState(true)}
                                    ><FaCamera /></Button> 
                                    <label htmlFor="icon-button-file">
                                        <Button variant="primary">
                                            <FaCamera />
                                        </Button>
                                    </label>

                                    <Button variant="info"
                                        onClick={() =>
                                            selectFiles({ accept: "image/*" }, ({ name, size, source, file }) => {
                                                setImage(source)
                                                console.log("Files Selected", { name, size, source, file });
                                            })}
                                    ><FaUpload /></Button>

                                </ButtonGroup> */}

                                <Button variant="danger"
                                    onClick={clearImage}
                                    block><FaDumpsterFire /> Clear photo</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={8}>
                        <Form>
                            <Form.Group as={Row} controlId="titleText">
                                <Form.Label column sm="2">
                                    Title
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control placeholder="Enter Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="upcText">
                                <Form.Label column sm="2">
                                    UPC/ASIN
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control placeholder="Enter UPC/Asin" value={upc} onChange={(e) => setUpc(e.target.value)} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="priceText">
                                <Form.Label column sm="2">
                                    Retail
                                </Form.Label>
                                <Col sm="4">
                                    <Form.Control placeholder="Enter Retail" value={retail} onChange={(e) => setRetail(e.target.value)} />
                                </Col>
                                <Form.Label column sm="2">
                                    Shelf no
                                </Form.Label>
                                <Col sm="4">
                                    <DropdownList filter
                                        data={shelves ? shelves : []}
                                        value={curShelf}
                                        onChange={value => handleCurShelf(value)}
                                        textField="shelfNo"
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} >
                                <Form.Label column sm="2">
                                    Auction Start Price
                                </Form.Label>
                                <Col sm="3">
                                    <Form.Control placeholder="Enter Auction Start Price" value={auctionStartPrice} onChange={(e) => setAuctionStartPrice(e.target.value)} />
                                </Col>
                                <Form.Label column sm="2">
                                    Qty
                                </Form.Label>
                                <Col sm="3">
                                    <Form.Control placeholder="Enter Qty" value={quantity} onChange={(e) => setAuctionStartPrice(e.target.value)} />
                                </Col>
                                <Col sm="2">
                                    <ButtonGroup id="qtyButtonGroup" aria-label="Basic example">
                                        <Button variant="outline-danger" className="qtyButton" onClick={increaseQuantity}>+</Button>
                                        <Button variant="outline-danger" className="qtyButton" onClick={decreaseQuantity}>-</Button>
                                    </ButtonGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2">
                                    Condition
                                </Form.Label>
                                <Col sm="4">
                                    <Dropdown onSelect={handleSelect}>
                                        <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" block >
                                            {condition}
                                        </Dropdown.Toggle >

                                        <Dropdown.Menu >
                                            <Dropdown.Item eventKey="New">New</Dropdown.Item>
                                            <Dropdown.Item eventKey="Like New">Like New</Dropdown.Item>
                                            <Dropdown.Item eventKey="Opened Box">Opened Box</Dropdown.Item>
                                            <Dropdown.Item eventKey="Damaged Box">Damaged Box</Dropdown.Item>
                                            <Dropdown.Item eventKey="Uninspected Returns">Uninspected Returns</Dropdown.Item>
                                            <Dropdown.Item eventKey="Salvage">Salvage</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Form.Label column sm="2">
                                    Categories
                                </Form.Label>
                                <Col sm="4">
                                   
                                    <Multiselect
                                        data={categories ? categories : []}
                                        value={curCategory}
                                        allowCreate="onFilter"
                                        onCreate={name => handleCategoryCreate(name)}
                                        onChange={value => setCurCategory(value)}
                                        textField="catalog"
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2">
                                    Description
                                </Form.Label>
                                <Col sm="10">
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={description}
                                        config={{
                                            removePlugins: ['Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed']
                                        }}
                                       
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setDescription(data);
                                            // console.log({ event, editor, data });
                                        }}
                                    
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Col sm="4" className="mt-2">
                                    <Button variant="success" block onClick={postProduct}><FaUpload /> Post Product </Button>
                                </Col>
                                <Col sm="4" className="mt-2">
                                    <Button variant="success" block ><FaUpload /> Print Label </Button>
                                </Col>
                                <Col sm="4" className="mt-2">
                                    <Button variant="danger" block onClick={clearAll}><FaBroom /> Clear All </Button>
                                </Col>
                                
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Card>

        </Fragment>
    )
}
